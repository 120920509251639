<template>
  <Form
    :hide-reset="edit"
    :title="title"
    :loading="loading"
    @submit="onSubmit"
    @reset="onReset"
  >
    <v-row>
      <FormItem
        v-model="code"
        type="text"
        name="Codice Esito"
        rules="required"
        :lg="3"
        :disabled="system && edit && initialSystem"
      />
      <FormItem
        v-model="status"
        type="select"
        :values="statuses"
        name="Associa allo stato"
        :lg="3"
      />
      <FormItem v-model="active" type="switch" name="Attivo" :lg="3" />
      <FormItem
        v-if="!edit || !initialSystem"
        v-model="system"
        type="switch"
        hint="Gli esiti di sistema non possono più essere modificati."
        :persistent-hint="true"
        name="Di sistema"
      />
    </v-row>
    <v-row>
      <FormItem
        v-model="success"
        type="switch"
        name="Chiusura con successo"
        :lg="3"
      />
      <FormItem
        v-model="show_in_app"
        type="switch"
        name="Selezionabile in APP"
        :lg="3"
      />
    </v-row>

    <v-row>
      <FormItem
        v-model="macros"
        :values="macrosByContext({ category: categories })"
        type="autocomplete"
        deletable-chips
        name="Limita alle commesse"
        :cols="12"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
        @input="updateCategories"
      />
    </v-row>
    <v-row>
      <FormItem
        v-model="categories"
        :values="categoriesByContext({ macro: macros })"
        :disabled="!macros.length"
        type="autocomplete"
        deletable-chips
        name="Limita alle categorie"
        :cols="12"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
      />
    </v-row>
  </Form>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'

const { mapFields } = createHelpers({
  getterType: 'ticketsResolutions/getCurrent',
  mutationType: 'ticketsResolutions/SET_CURRENT_FIELDS',
})

export default {
  name: 'TicketsResolutionsForm',
  components: {
    FormItem,
    Form,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Crea Nuovo',
    },
  },
  data() {
    return {
      // Saves the initial value for system so the toggle doesn't disappear
      initialSystem: null,
    }
  },
  watch: {
    /**
     * When the status is loaded store the initial system
     * value so the toggle doesn't disappear
     */
    id: {
      immediate: true,
      handler: function () {
        this.initialSystem = this.system
      },
    },
  },
  computed: {
    ...mapFields([
      'active',
      'categories',
      'code',
      'macros',
      'show_in_app',
      'status',
      'success',
      'system',
    ]),
    ...mapState('ticketsResolutions', {
      id: (state) => state.current.id,
      loading: (state) => state.loading,
    }),
    ...mapGetters('ticketsActivities', {
      macrosByContext: 'macrosByContext',
      categoriesByContext: 'categoriesByContext',
    }),
    ...mapGetters('ticketsStatuses', {
      statuses: 'getStatuses',
    }),
  },
  mounted() {},
  methods: {
    ...mapMutations('ticketsResolutions', {
      reset: 'RESET_CURRENT',
      setCurrentValues: 'SET_CURRENT',
    }),
    ...mapActions('ticketsResolutions', [
      'create',
      'update',
      'setCurrent',
      'remove',
      'getItems',
    ]),
    async onSubmit() {
      try {
        this.$emit('submit')

        if (!this.edit) {
          await this.create()
        } else {
          await this.update()
        }

        this.$emit('submitted', this.id)
      } catch (err) {
        console.log('errore', err)
      }
    },
    onReset() {
      const current = this.id
      this.reset()
      if (current) this.setCurrent(current)
      this.$emit('resetted')
    },
    updateCategories(macros) {
      if (!macros.length) this.categories = []
    },
  },
}
</script>

<style scoped></style>
