var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-lg-10",attrs:{"fluid":""}},[_c('TicketResolutionsSearch',{on:{"search":function () { return _vm.$refs.table.refresh(); }}}),_c('BaseTable',{ref:"table",attrs:{"title":"Esiti","module":"ticketsResolutions","headers":_vm.headers,"actions":_vm.actions,"sortby":"code","sort-desc":false},scopedSlots:_vm._u([{key:"item.opening",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.opening ? 'SI' : 'NO')+" ")]}},{key:"item.success",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.success ? 'SI' : 'NO')+" ")]}},{key:"item.show_in_app",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.show_in_app ? 'SI' : 'NO')+" ")]}},{key:"item.macros",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.macros.join(', '))+" ")]}},{key:"item.categories",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.categories.join(', '))+" ")]}},{key:"actions",fn:function(){return [(_vm.canUser('tickets_resolutions', 'create'))?_c('v-btn',{attrs:{"color":"green","dark":"","tile":""},on:{"click":_vm.openModal}},[_vm._v(" Aggiungi esito ")]):_vm._e()]},proxy:true}])}),_c('v-dialog',{attrs:{"max-width":"75%","retain-focus":false},on:{"click:outside":_vm.modalClose},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.editMode)?_c('v-card-title',[_vm._v(" Modifica Dettagli")]):_c('v-card-title',[_vm._v("Crea nuovo esito")]),_c('v-card-text',[_c('TicketsResolutionsForm',{attrs:{"title":null,"edit":_vm.editMode},on:{"submitted":_vm.onSubmitted}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }