<template>
  <v-container fluid class="px-lg-10">
    <TicketResolutionsSearch @search="() => $refs.table.refresh()" />
    <BaseTable
      ref="table"
      title="Esiti"
      module="ticketsResolutions"
      :headers="headers"
      :actions="actions"
      sortby="code"
      :sort-desc="false"
    >
      <template v-slot:item.opening="{ item }">
        {{ item.opening ? 'SI' : 'NO' }}
      </template>
      <template v-slot:item.success="{ item }">
        {{ item.success ? 'SI' : 'NO' }}
      </template>
      <template v-slot:item.show_in_app="{ item }">
        {{ item.show_in_app ? 'SI' : 'NO' }}
      </template>
      <template v-slot:item.macros="{ item }">
        {{ item.macros.join(', ') }}
      </template>
      <template v-slot:item.categories="{ item }">
        {{ item.categories.join(', ') }}
      </template>
      <template v-slot:actions>
        <v-btn
          v-if="canUser('tickets_resolutions', 'create')"
          color="green"
          dark
          tile
          @click="openModal"
        >
          Aggiungi esito
        </v-btn>
      </template>
    </BaseTable>
    <v-dialog
      v-model="dialog"
      max-width="75%"
      :retain-focus="false"
      @click:outside="modalClose"
    >
      <v-card>
        <v-card-title v-if="editMode"> Modifica Dettagli</v-card-title>
        <v-card-title v-else>Crea nuovo esito</v-card-title>
        <v-card-text>
          <TicketsResolutionsForm
            :title="null"
            :edit="editMode"
            @submitted="onSubmitted"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import BaseTable from '@components/structure/base-table.vue'
import TicketsResolutionsForm from '@components/tickets/resolutions/TicketsResolutionsForm.vue'
import TicketResolutionsSearch from '@components/tickets/resolutions/TicketsResolutionsSearch.vue'

import { authComputed } from '@state/helpers.js'
export default {
  name: 'TicketsResolutionSearch',
  components: { TicketsResolutionsForm, TicketResolutionsSearch, BaseTable },
  page: {
    title: 'Esiti ticket',
  },
  data() {
    return {
      dialog: false,
      editMode: false,
      headers: [
        {
          text: 'Codice',
          value: 'code',
        },
        {
          text: 'Stato',
          value: 'status',
        },
        {
          text: 'Successo',
          value: 'success',
        },
        {
          text: 'Visibile in APP',
          value: 'show_in_app',
        },
        {
          text: 'Commesse',
          value: 'macros',
        },
        {
          text: 'Categorie',
          value: 'categories',
        },

        {
          text: 'Attivo',
          value: 'active',
        },
      ],
      actions: [
        {
          key: 'edit',
          label: 'Modifica',
          icon: 'mdi-pencil',
          color: 'primary',
          enabled: true,
          onItemCondition: () => this.canUser('tickets_resolutions', 'update'),
          handler: this.openModal,
        },
        {
          key: 'remove',
          label: 'Elimina asset',
          icon: 'mdi-delete',
          color: 'red',
          onItemCondition: (item) =>
            !item.system && this.canUser('tickets_resolutions', 'delete'),
          handler: this.remove,
        },
      ],
    }
  },
  computed: {
    ...authComputed,
  },
  methods: {
    ...mapActions('ticketsResolutions', ['removeItem']),
    ...mapMutations('ticketsResolutions', {
      setCurrent: 'SET_CURRENT',
      resetCurrent: 'RESET_CURRENT',
    }),

    openModal(item) {
      const startingPoint = item.id ? item : {}
      // Understand if the modal was opened as editing or for a new item
      const isEditing = !!startingPoint.id
      this.editMode = isEditing
      this.setCurrent(startingPoint)
      this.dialog = true
    },

    onSubmitted() {
      this.modalClose()
      this.$refs.table.refresh()
    },
    modalClose() {
      this.dialog = false
      this.editMode = false
      this.resetCurrent()
    },

    async remove(item) {
      const res = await this.$dialog.confirm({
        text: "Proseguire con l'eliminazione?\n L'azione non è reversibile!",
        title: 'Attenzione',
      })

      if (!res) return

      await this.removeItem(item.id)
      this.$refs.table.refresh()
    },
  },
}
</script>
