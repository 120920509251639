<template>
  <BaseSectionSearch @submit="onSubmit" @reset="onReset">
    <v-row dense>
      <FormItem
        v-model="code"
        type="text"
        name="Codice"
        hint="Ricerca per Codice"
        dense
      />
      <FormItem
        v-model="status"
        :values="statuses"
        type="autocomplete"
        deletable-chips
        name="Filtra per Stato Associato"
        dense
      />

      <FormItem
        v-model="active"
        type="select"
        :values="[
          { value: 'true', text: 'Attivo' },
          { value: 'false', text: 'Inattivo' },
        ]"
        name="Stato"
        dense
      />

      <FormItem
        v-model="macros"
        :values="macrosByContext({ category: categories })"
        type="autocomplete"
        deletable-chips
        name="Limitati alle commesse"
        dense
      />

      <FormItem
        v-model="categories"
        :values="categoriesByContext({ macro: macros })"
        type="autocomplete"
        deletable-chips
        name="Limita alle categorie"
        dense
      />
    </v-row>
  </BaseSectionSearch>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapGetters, mapMutations, mapState } from 'vuex'

import BaseSectionSearch from '@components/structure/base-section-search.vue'
import FormItem from '@components/forms/FormItem.vue'

const { mapFields } = createHelpers({
  getterType: 'ticketsResolutions/getFiltersFields',
  mutationType: 'ticketsResolutions/SET_FILTER_FIELDS',
})

export default {
  name: 'TicketsResolutionsSearch',
  components: { FormItem, BaseSectionSearch },
  computed: {
    ...mapFields(['active', 'macros', 'categories', 'code', 'status']),
    ...mapState('ticketsResolutions', ['filters']),
    ...mapGetters('ticketsStatuses', {
      statuses: 'getStatuses',
    }),
    ...mapGetters('ticketsActivities', {
      macrosByContext: 'macrosByContext',
      categoriesByContext: 'categoriesByContext',
    }),
  },
  mounted() {
    this.reset()
  },
  methods: {
    ...mapMutations('ticketsResolutions', {
      reset: 'RESET_FILTER_FIELDS',
    }),
    onSubmit() {
      this.$emit('search')
    },
    onReset() {
      this.reset()
      this.$emit('search')
    },
  },
}
</script>

<style scoped></style>
